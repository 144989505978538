<template>
  <div id="roadmaps-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <!--<router-link class="btn btn-success mb-5" to="/roadmaps/add">Roadmap-Slot hinzufügen</router-link>-->

    <ComingSoonBanner></ComingSoonBanner>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calendar View</li>
      <li>Build Roadmap View</li>
      <li>Build List View</li>
      <li>Be able to add and change items directly in View</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/ideas">Backlog Ideas</router-link></li>
      <li>Connect to <router-link to="/todos">To Dos</router-link></li>
    </ul>-->
<!--
    <table class="table">
      <thead>
        <tr>
          <th class="border-right" width="35%">
            <span class="mt-2">Termin \ KW</span>
            <button @click="navigateRange(-1)" class="btn btn-secondary float-right"><i class="fal fa-angle-left"></i></button>
          </th>
          <th v-for="i in roadmapWeekRange.slice(0, -1)"
              :width="65 / roadmapWeekRange.length + '%'"
              class="pb-3 border-right roadmap-column-header"
              :class="i === currentWeek ? 'text-primary' : ''"
              :key="i">
            {{ i }} <br>
            <span class="small">{{ getDates(i)[0] }}</span>
          </th>
          <th>
            <button @click="navigateRange(1)" class="btn btn-secondary"><i class="fal fa-angle-right"></i></button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in slots" :key="index" class="roadmap-table">
          <td class="pt-3 pb-4 border-right">
            <h3 class="h5">{{ item.title }} ({{ item.category }})</h3>
            <span class="badge p-2 mr-2" :class="'badge-' + typeColorMapping[item.type]"><i class="fad"
                                                                                            :class="'fa-signal-alt' + priorityMapping[item.priority]"></i></span>
            <span v-for="(device, index) in item.device" class="badge badge-dark p-2 mr-2" :key="index">
              <i class="m-0" :class="deviceMapping[device]"></i>
            </span>
          </td>
          <td v-for="i in roadmapWeekRange"
              class="roadmap-column text-center"
              :class="currentYear === item.startYear && (item.startWeek <= i && i <= (item.startWeek + item.durationWeeks)) ? ('bg-' + typeColorMapping[item.type]) : ''"
               :key="i">
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>-->
  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";
export default {
  name: 'Roadmaps',
  components: {
    ComingSoonBanner
  },
  data() {
    return {
      currentYear: 2021,
      currentWeek: 0,
      activeYear: 2021,
      roadmapWeekRange: [],
      typeMapping: {
        "standalone": "Termin",
        "ideas": "Idee",
        "experiments": "Experiment",
        "audits": "Audit"
      },
      typeColorMapping: {
        "standalone": "primary",
        "ideas": "success",
        "experiments": "warning",
        "audits": "danger"
      },
      deviceMapping: {
        "Desktop": "fad fa-desktop",
        "Tablet": "fad fa-tablet",
        "Mobile": "fad fa-mobile"
      },
      priorityMapping: {
        1: "-1",
        2: "-2",
        3: "-3",
        4: ""
      }
    }
  },
  computed: {
    slots() {
      return this.project.slots.map(item => {
        if (item.type !== "standalone") {
          let connectedItem = this.project[item.type].find(i => item.id === i.slotId);
          if (connectedItem) {
            item.connectedItem = connectedItem;
            item.title = connectedItem.title;
            item.device = connectedItem.device;
          }
        }
        item.category = this.typeMapping[item.type];
        return item;
      });
    }
  },
  methods: {
    navigateRange(direction) {
      if (direction === 1) {
        this.roadmapWeekRange.splice(0, 1);
        let weekAfter = this.roadmapWeekRange[this.roadmapWeekRange.length - 1] + 1;
        if (weekAfter > 52) {
          weekAfter = 1;
        }
        this.roadmapWeekRange.push(weekAfter);
      } else {
        this.roadmapWeekRange.pop();
        let weekBefore = this.roadmapWeekRange[0] - 1;
        if (weekBefore < 1) {
          weekBefore = 52;
        }
        this.roadmapWeekRange.splice(0, 0, weekBefore)
      }
    },
    getDates(cw) {
      let ms = 1000,
          s = 60,
          m = 60,
          h = 24,
          d = 7;
      let dayDelta = ms * s * m * h;
      let weekDelta = dayDelta * d;
      let yearStart = new Date(this.currentYear, 0, 1);
      let startDayIndex = yearStart.getUTCDay();
      let startOffset = (8 - startDayIndex) * dayDelta;
      let startDate = Date.parse(yearStart) + startOffset + weekDelta * cw;

      return [new Date(startDate).toISOString().slice(5, 10), new Date(startDate + weekDelta).toISOString().slice(5, 10)];
    }
  },
  beforeMount() {
    let now = new Date();
    let firstOfYear = new Date(now.getFullYear(), 0, 1);
    this.currentWeek =
        Math.ceil((((now.getTime() - firstOfYear.getTime()) / 86400000) + firstOfYear.getDay() + 1) / 7 );

    this.roadmapWeekRange = [];
    if (this.$route.params.date) {
      this.activeYear = this.$route.params.date.slice(0, 4);
      let week = this.$route.params.date.slice(5, 7);
      this.roadmapWeekRange = [];
      for (let i = week - 1; i < 9; i++) {
        this.roadmapWeekRange.push(i);
      }
    } else {
      for (let i = 0; i < 9; i++) {
        this.roadmapWeekRange.push(this.currentWeek - 1 + i);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table thead th {
    vertical-align: middle;
  }
  .roadmap-table {
    justify-content: stretch;
    align-items: stretch;
  }
  .roadmap-column, .roadmap-column-header {
    vertical-align: center;
  }
  .roadmap-column {
  }
  .roadmap-column-header {
    text-align: center;
    font-weight: bold;
  }
</style>